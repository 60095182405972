<template>
  <NuxtLink :href="prettyUrl" class="border-none bg-transparent hover:bg-transparent">
    <article class="h-full">

      <div class="relative flex flex-1 flex-col rounded-2xl overflow-hidden">
        <div :class="[imgZoomClassname]" :style="`filter:contrast(${imgContrast});`" class="w-full relative aspect-square max-h-48 object-contain">
          <CPicture lazy :src="imgUrl" :fp-x="fpX" :fp-y="fpY" :fit="fit" sizes="(max-width: 640px) 420px, 420px"
                    class="[transition:transform_6s_ease-out] hover:[transform:scale(1.28)] hover:[transition:transform_0.6s_ease-in]" />
        </div>
        <div class="ml-2 sm:ml-3 sm:mr-3 absolute top-3 left-0 font-bold text-black text-sm flex flex-wrap flex-grow-0 gap-y-2 items-center">
          <div v-for="tag in tagCloud" class="mr-2 px-2 bg-white bg-opacity-80 rounded-lg">
            <div>{{ tag }}</div>
          </div>
        </div>
      </div>

      <div class="mt-4 px-1 relative flex flex-1 flex-col">
        <div class="flex flex-1 flex-col">
          <div class="pr-3 flex flex-1 justify-end items-center">
            <div v-if="priceInfo" class="-translate-y-8 py-0.5 px-3 tracking-wider rounded-2xl max-w-fit bg-white"><span class="text-xs">p.P. ab</span> <span class="text-accent-500 text-xl sm:text-xl">{{ priceInfo }} &euro;</span></div>
            <div v-else class="h-8"></div>
          </div>
          <div class="-mt-8 flex flex-col justify-center gap-y-0.5">
            <div class="flex items-center">
              <CWidgetSvg name="ship" class="mr-2 w-4 h-4 text-amber-500" />
              <div class="text-glam-600 text-sm uppercase">{{ red_name }}</div>
            </div>

            <div class="flex items-center">
              <p v-if="timeframe" class="mr-2 text-sm 1font-semibold">{{ timeframe }}</p>
              <CWidgetSvg v-if="flight" name="plane_up" class="w-4 text-amber-500"></CWidgetSvg>
            </div>

            <p class="mt-1 text-xl font-semibold">{{ route }}</p>
            <p class="mt-0.5 text-sm tracking-wider">{{ routeInfo }}</p>
          </div>
        </div>
      </div>

    </article>
  </NuxtLink>
</template>

<script setup lang="ts">
export interface Props {
  cta?: string
  fit?: 'cover' | 'contain' | 'fill' | 'inside' | 'outside' | 'clamp' | 'clip' | 'crop' | 'facearea' | 'fillMax' | 'max' | 'min' | 'scale'
  fpX?: number
  fpY?: number
  heading?: string
  imgContrast?: number
  imgDisclaimer?: string
  imgUrl: string
  flight?: boolean
  prettyUrl: string
  priceInfo?: string
  red_name: string
  route?: string
  routeInfo?: string
  tags?: string[]
  timeframe?: string
  zoomDirection?: 'in' | 'out' | 'none'
}
const props = withDefaults(defineProps<Props>(), {
  fpX: 0.5,
  fpY: 0.5,
  heading: 'h4',
  imgContrast: 1.2,
  zoomDirection: 'out',
})
const imgZoomClassname = {
  'in': '[animation:cos-animation-zoom-out_40s_linear_1_0s]',
  'out': '[animation:cos-animation-zoom-in_40s_linear_1_0s]',
  'none': '',
}[props.zoomDirection]

// let tagCloud = [props.route]
let tagCloud: string[] = []
if (props.tags !== undefined) {
  tagCloud = tagCloud.concat(props.tags)
}
if (props.flight) {
  tagCloud = tagCloud.concat('inkl. Flug')
}

// if (props.red_name === 'AIDA Cruises') {
// }

// tagCloud = tagCloud.concat([''])

</script>
