<template>
  <NuxtLink :external="external" :href="href" :target="target" :class="styled" :aria-label="labelText">
    <slot>Mehr erfahren</slot>
  </NuxtLink>
</template>

<script setup lang="ts">
export interface Props {
  href: string
  external?: boolean
  label: string
  target?: '_blank' | '_parent' | '_self' | '_top' | (string & {}) | null
  symbol?: 'none' | undefined | 'arrow right'
  overrideSymbol?: string
  overrideStyles?: string
}

const props = withDefaults(defineProps<Props>(), {
  symbol: 'arrow right',
  overrideStyles: 'bg-transparent hover:bg-transparent border-none',
  target: undefined,
  overrideSymbol: undefined,
})

const symMap = {
  'none': '',
  'arrow right': "after:content-['\\2794']",
}

const sym = symMap[props.symbol ?? 'none']

const finalSym = 'after:ms-2 ' + sym

const styled = [
  props.overrideStyles,
  finalSym,
].join(' ')

// symbol: '↗ ↗',
// symbol: "\\2764",
// arrow right  \27F6
// arrow right  \279D

// HEAVY WIDE-HEADED RIGHT ARROW \2794
// https://www.toptal.com/designers/htmlarrows/arrows/

const labelText = props.label || useSlots().default
</script>
