<template>
  <div class="cos-page">

    <div>
      <CHeroHeader alt="HOTELS weltweit buchen" src="images/afrika/seychellen/pexels-moritz-feldmann-5048621.jpg" fit="crop" layout="large" blend-mode="readable">
        <div class="max-sm:px-8 h-full flex items-center container">
          <div class="text-white">
            <h1 class="font-serif text-5xl sm:text-7xl max-w-2xl tracking-tight [text-shadow:_0_2px_3px_rgb(0_0_0_/_20%)]">
              Wir finden deine <strong>Traum&shy;hotels</strong> weltweit!
            </h1>
          </div>
        </div>
      </CHeroHeader>

      <CPageContentSection class="mt-10 sm:mt-10">
        <h2 data-aos="fade-right" class="pt-6 font-medium text-xs text-center uppercase tracking-widest text-lux-400">Entdecke die Welt mit den <strong>{{ company.name }} Premium-Partnern</strong></h2>
      </CPageContentSection>
      <CPartnerMarquee data-aos="fade-in" class="py-5" />
    </div>

    <CPageContentSection data-aos="fade-in" class="sm:-mt-10 text-center">
      <CTypestyleHeadline>
        <h3 data-aos="zoom-in" class="font-light text-3xl sm:text-5xl tracking-tight">Für Weltentdecker und <CTypestyleBoldstrong>Urlaubsgenießer</CTypestyleBoldstrong>
        </h3>
      </CTypestyleHeadline>
      <p data-aos="fade-left" class="mt-2"><strong>Rundreisen</strong>, <strong>Kreuzfahrten</strong>, <strong>Expeditionsreisen</strong>, <strong>Fitnessreisen</strong>, <strong>Golfreisen</strong> und Top <strong>Hotels</strong> zu besten Preisen</p>
      <!-- <h4 data-aos="fade-right" class="mt-2 1text-lg 1sm:text-xl text-lux-400">Jetzt buchen und smarte Preise sichern.</h4> -->
    </CPageContentSection>

    <CPageSection data-aos="fade-up">
      <CPartnerOfferBanner caption="Traumhafte Kreuzfahrt: Entdecke die Karibik & Nordamerikas Ostküste" img-url="images/ship/explora-journeys/explora-i/explora-i-1200x628-2.jpg" :href="`${$c.engine.urls.offer}cruise_id=822-241122-14&p=3&type=NS`">
        <p>Genieße puren Luxus mit der Explora II ab/bis Miami.</p>
      </CPartnerOfferBanner>
    </CPageSection>

    <CPageColumnSection data-aos="fade-up">
      <template #header>
        <div class="sm:pt-6 pb-6 flex items-center cos-nav-none">
          <NuxtIcon name="majesticons:ship-line" size="64px" class="mr-2 text-mongoose-500" />
          <NuxtLink href="/kreuzfahrten" aria-label="Kreuzfahrten">
            <h3 class="font-semibold text-xl tracking-tight"><strong>Kreuzfahrten</strong> weltweit</h3>
          </NuxtLink>
        </div>
      </template>
      <CCruisesOffersCardFlat :red_name="$c.companyMeta.aida.name" :img-url="$c.companyMeta.aida.image.aida_prima_1" route="Dubai, Bangkok, Singapur und mehr" price-info="1.905" timeframe="Von Nov-2024 bis Dez-2025" route-info="Mach dich auf in die Region der aufgehenden Sonne mit AIDA." :tags="['7 bis 20 Nächte']" flight :pretty-url="`${$c.engine.urls.cruise.aida}&${$c.engine.params.region.orient}&t=S`" />
      <CCruisesOffersCardFlat :red_name="$c.companyMeta.costa.name" :img-url="$c.companyMeta.costa.image.costa_smeralda_1" route="Erlebe das Land der aufgehenden Sonne" price-info="1.744" timeframe="Von Dez-2024 bis Mär-2025" route-info="Lass Dich von der Vielfalt des Orients mit Costa verzaubern." :tags="['7 bis 20 Nächte']" flight :pretty-url="`${$c.engine.urls.cruise.costa}&${$c.engine.params.region.orient}&t=S`" />
      <CCruisesOffersCardFlat :red_name="$c.companyMeta.hurtigruten.name" :img-url="$c.companyMeta.hurtigruten.image.hurtigruten_ms_maud_1" route="Communities in the Caribbean" price-info="3.792" timeframe="September 2024" route-info="Entdecke Tropische Gewässer und abgelegene Inseln." :tags="['12 Nächte']" :pretty-url="`${$c.engine.urls.cruise.hurtigruten}&${$c.engine.params.region.karibik}`" />

      <CCruisesOffersCardFlat :red_name="$c.companyMeta.explora.name" :img-url="$c.companyMeta.explora.image.explora_I_3" route="Miami, San Juan, Bridgetown und mehr" price-info="2.695" timeframe="Von Nov-2024 bis Dez-2025 - 7 bis 21 Nächte" route-info="Karibik Kreuzfahrt mit Explora Journeys." :pretty-url="`${$c.engine.urls.cruise.explora}&${$c.engine.params.region.karibik}&t=NS`" />
      <CCruisesOffersCardFlat :red_name="$c.companyMeta.ncl.name" :img-url="$c.companyMeta.ncl.image.ncl_prima_viva_1" route="Doha, Dubai, Bangkok, Singapur und mehr" price-info="1.089" timeframe="Von Dez-2024 bis Feb-2026 - 7 bis 17 Nächte" route-info="Asien Kreuzfahrt mit Norwegian Cruise Line." :pretty-url="`${$c.engine.urls.cruise.ncl}&${$c.engine.params.region.asien}&t=NS`" />
      <CCruisesOffersCardFlat :red_name="$c.companyMeta.carnival.name" :img-url="$c.companyMeta.carnival.image.carnival_unsplash_1" route="Mexikanische Riviera" price-info="373" timeframe="Von Jul-2024 bis Jul-2025 - 7 bis 8 Nächte" route-info="Entdecke Long Beach - Mexiko mit der Carnival Panorama." :tags="['6 bis 8 Nächte']" :pretty-url="`${$c.engine.urls.ships.carnival.panorama}&${$c.engine.params.region.mexiko}`" />
      <template #footer>
        <CLinkWSymbol class="my-6 sm:my-12 text-lg w-fit" href="/kreuzfahrten" label="Kreuzfahrtangebote" caption="Entdecke viele weitere Kreuzfahrtangebote" />
      </template>
    </CPageColumnSection>

    <CPageSection data-aos="fade-up">
      <CPartnerOfferBanner caption="Lust auf Bahamas,  Karibik, Hawaii?" img-url="images/cruises/carnival/cody-mclain-IqiphbYPMmk-unsplash.jpg" href="/kreuzfahrt-reederei/carnival-kreuzfahrten" target="_self">
        <p>Exotische Schiffsreisen mit <strong>Carnival Cruise Line</strong>.</p>
        <p class="mt-2 italic"><strong>Bonus</strong>: Erlebe das Beste aus beiden Welten mit unseren einzigartigen Kombireisen, die Kreuzfahrt und Hotelaufenthalt vereinen.</p>
        <p class="mt-2 italic">Entdecke die Möglichkeiten und ruf jetzt an.</p>
      </CPartnerOfferBanner>
    </CPageSection>

    <CPageColumnSection data-aos="fade-up">
      <template #header>
        <div class="sm:pt-6 pb-6 flex items-center">
          <NuxtIcon name="material-symbols-light:luggage-outline-rounded" size="64px" class="mr-2 text-mongoose-500" />
          <NuxtLink href="/pauschalreisen/suche" aria-label="Pauschalreisen">
            <h3 class="font-semibold text-xl tracking-tight">Luxus <strong>Hotels</strong> Inspirationen</h3>
          </NuxtLink>
        </div>
      </template>
      <CHotelCardRounded caption="Jumeirah Umm Suqeim, Dubai" hotel-name="Burj Al Arab Jumeirah" :stars="6" price-info="3.960" alt="Burj Al Arab Jumeirah, Dubai" description="z.B. 1 Woche in der Deluxe Marina Suite mit Meerblick und Halbpension" subline="Total Luxury Experience" href="/hotels/dubai-burj-al-arab-jumeirah-angebote" src="images/vae/dubai/hotels/burj-al-arab-jumeirah.jpg" />
      <CHotelCardRounded caption="Anse Possession, Seychellen" hotel-name="Raffles Seychelles" :stars="6" price-info="4.451" alt="Raffles Seychelles Hotel, Seychellen" description="z.B. 8 Tage in der Ocean View Pool Villa mit Meerblick und Vollpension" subline="Total Luxury Experience" href="/hotels/seychellen-raffles-seychelles-angebote" src="images/afrika/seychellen/hotels/Raffles Seychelles/raffles-seychelles.jpg" />
      <CHotelCardRounded caption="Rasdhoo (Nord Ari) Atoll, Malediven" hotel-name="Kuramathi Island Resort" :stars="5" price-info="1.410" alt="Kuramathi Island Resort, Malediven" description="z.B. 8 Tage in der Garden Villa mit Vollpensions" href="/hotels/malediven-kuramathi-island-angebote" src="images/asien/malediven/hotel/Kuramathi Island Resort/kuramathi-island-resort-2.jpg" />

      <CHotelCardRounded caption="The Palm Jumeirah, Dubai" hotel-name="W Dubai - The Palm" :stars="5" price-info="1.247" alt="W Dubai - The Palm, Dubai" description="z.B. 1 Woche im Wonderful Room King mit Meerblick und Halbpension" href="/hotels/dubai-w-the-palm-angebote" src="images/vae/dubai/hotels/w-dubai-the-palm.jpg" />
      <CHotelCardRounded caption="The Palm Jumeirah, Dubai" hotel-name="Rixos The Palm Dubai Hotel & Suites" :stars="5" price-info="1.529" alt="Rixos The Palm Dubai Hotel & Suites, Dubai" description="z.B. 1 Woche im Deluxe Room mit Meerblick und All Inclusive" href="/hotels/dubai-rixos-the-palm-angebote" src="images/vae/dubai/hotels/rixos-the-palm-dubai-hotel-and-suites.jpg" />
      <CHotelCardRounded caption="Jumeirah Beach Residence JBR, Dubai" hotel-name="The Ritz-Carlton" :stars="5" price-info="1.520" alt="The Ritz-Carlton Hotel, Dubai" description="z.B. 1 Woche in der Ocean View Deluxe mit Meerblick und Halbpension" subline="Total Luxury Experience" href="/hotels/dubai-the-ritz-carlton-angebote" src="images/vae/dubai/hotels/the-ritz-carlton.jpg" />
      <template #footer>
        <div class="sm:mt-6 py-6 flex flex-wrap items-center">
          <CLink href="/pauschalreisen/suche" label="Kreuzfahrtangebote" icon="Arrow">
            <div class="text-lg">Entdecke viele weitere Pauschalreiseangebote</div>
          </CLink>
        </div>
      </template>
    </CPageColumnSection>

    <CPageSection data-aos="fade-up">
      <CPartnerOfferBanner caption="Ibiza-Urlaub: Unvergessliche Erlebnisse." img-url="images/country/spanien/balearen/ibiza/coast-3949782.jpg" href="/urlaub/spanien/balearen/ibiza" target="_self">
        <p>Das pulsierende Leben der Baleareninsel.</p>
      </CPartnerOfferBanner>
    </CPageSection>

    <CPageSection>
      <CColsBanner data-aos="fade-right">
        <CColsBannerItem href='/kreuzfahrt-reederei/msc-kreuzfahrten' img-url='images/cruises/misc/MSC22013757.jpg' origin='Beliebt' alt='MSC Cruises Kreuzfahrten'><strong>MSC Cruises</strong> - <strong>Catch of the week</strong></CColsBannerItem>
        <CColsBannerItem href='/urlaub/spanien/balearen/ibiza' img-url='images/country/spanien/balearen/ibiza/michael-tomlinson-dZBQbB9TaIQ-unsplash.jpg' origin='Beliebt' alt='Ibiza Urlaub'><strong>Ibiza Urlaub</strong> - Sonne, Strand und unvergeßliche Nächte.</CColsBannerItem>
        <CColsBannerItem href='/urlaub/vereinigte-arabische-emirate/dubai' img-url='images/vae/dubai/saj-shafique-de7Zqg3j3FI-unsplash.jpg' origin='Beliebt' alt='Dubai Urlaub'><strong>Dubai Urlaub</strong> - Erlebe Luxus und Abenteuer</CColsBannerItem>
      </CColsBanner>
    </CPageSection>

    <CPageSection data-aos="fade-up">
      <div class="sm:pt-6 pb-6 flex items-center">
        <NuxtIcon name="majesticons:beach-line" size="64px" class="mr-2 text-mongoose-500" />
        <h3 class="font-semibold text-xl tracking-tight">Beliebte <strong>Hotspots</strong> zu Land und auf See</h3>
      </div>
      <div class="grid grid-cols-2 lg:grid-cols-4 gap-6 mx-auto">
        <div class="grid gap-6">
          <CPartnerTeaserSmall :src="$c.companyMeta.hurtigruten.image.hurtigruten_ms_roald_amundsen" href="/kreuzfahrten/hurtigruten-expeditions" :alt="`${$c.companyMeta.hurtigruten.name} Seereisen`" :caption="$c.companyMeta.hurtigruten.name_short" height="h-80" />
          <CPartnerTeaserSmall :src="$d.asien.malediven.destination.robinson_maledives.image.robinson_maledives_image1" href="/pauschalreisen/cluburlaub/robinson-premium-cluburlaub" alt="`Urlaub im ROBINSON Club" caption="ROBINSON Club" height="h-40" />
          <CPartnerTeaserSmall :src="$c.companyMeta.aida.image.aida_prima_1" href="/kreuzfahrt-reederei/aida-kreuzfahrten" :alt="`${$c.companyMeta.aida.name_short} Kreuzfahrten`" :caption="$c.companyMeta.aida.name_short" />
        </div>
        <div class="grid gap-6">
          <CPartnerTeaserSmall :src="$d.nordamerika.mexiko.image.hero_image" href="/urlaub/mexiko" alt="Urlaub in Mexiko" caption="Mexiko" />
          <CPartnerTeaserSmall :src="$d.asien.malediven.image.hero_image" href="/urlaub/asien/malediven" alt="Urlaub auf den Malediven" caption="Malediven" height="h-80" />
          <CPartnerTeaserSmall :src="$d.nordamerika.usa.image.hero_image" href="/urlaub/usa" alt="Urlaub in USA" caption="USA" height="h-40" />
        </div>
        <div class="grid gap-6">
          <CPartnerTeaserSmall :src="$d.asien.thailand.image.robinson_khao_lak" href="/urlaub/asien/thailand" alt="Urlaub in Thailand" caption="Thailand" height="h-80" />
          <CPartnerTeaserSmall :src="$d.afrika.seychellen.image.hero_image" href="/urlaub/afrika/seychellen" alt="Urlaub auf den Seychellen" caption="Seychellen" height="h-40" />
          <CPartnerTeaserSmall :src="$d.afrika.mauritius.image.hero_image" href="/urlaub/afrika/mauritius" alt="Urlaub auf Mauritius" caption="Mauritius" />
        </div>
        <div class="grid gap-6">
          <CPartnerTeaserSmall :src="$d.afrika.vae.dubai.image.hero_image" href="/urlaub/vereinigte-arabische-emirate/dubai" alt="Urlaub in Dubai" caption="Dubai" height="h-40" />
          <CPartnerTeaserSmall :src="$c.companyMeta.ncl.image.ncl_prima_1" href="/kreuzfahrt-reederei/ncl-kreuzfahrten" :alt="`${$c.companyMeta.ncl.name_short} Kreuzfahrten`" :caption="$c.companyMeta.ncl.name_short" height="h-96" />
          <CPartnerTeaserSmall :src="$c.companyMeta.explora.image.explora_I_2" href="/luxuskreuzfahrten/explora-journeys" :alt="`${$c.companyMeta.explora.name} Seereisen`" :caption="$c.companyMeta.explora.name" height="h-40" />
        </div>
      </div>
    </CPageSection>

    <CPageSection data-aos="zoom-in" horizontal-lines>
      <NuxtLink
                :href="`${$c.companyMeta.msc.url_reederei}#catch`"
                aria-label="MSC Cruieses Catch of the week"
                class="flex flex-1 mx-auto rounded-2xl overflow-hidden max-w-4xl border-none w-full 1h-max 1object-contain">
        <NuxtImg
                 :src="$c.companyMeta.msc.image.msc_catch_of_the_week"
                 alt="MSC Catch of the week"
                 :modifiers="{ class: 'w-full h-full' }"
                 class="w-full h-fit rounded-2xl overflow-hidden mx-auto flex hover:bg-none object-contain bg-red-300"
                 sizes="(max-width: 640px) 420px, 420px"
                 fit="cover" />
      </NuxtLink>
    </CPageSection>

    <CPageColumnSection data-aos="fade-up" columns="3">
      <template #header>
        <div class="sm:pt-6 pb-6 flex items-center">
          <NuxtIcon name="material-symbols-light:hotel-class-outline-rounded" size="80px" class="mr-2 text-mongoose-500 shrink-0" />
          <h3 class="font-semibold text-xl tracking-tight">Top Destinationen und Informationen für deinen <strong>Urlaub</strong></h3>
        </div>
      </template>

      <CListCard
                 claim="Kreuzfahrten" title="Top Kreuzfahrtangebote" description="Entdecke das Meer neu mit Top-Schiffen, attraktiven Destinationen und Erlebnissen." img-url="images/cruises/carnival/cody-mclain-IqiphbYPMmk-unsplash.jpg" :tags="['Kreuzfahrten']"
                 :items="[
                  { caption: 'Kreuzfahrt MSC Cruises', href: '/kreuzfahrt-reederei/msc-kreuzfahrten' },
                  { caption: 'Kreuzfahrt AIDA Cruises', href: '/kreuzfahrt-reederei/aida-kreuzfahrten' },
                  { caption: 'Kreuzfahrt Carnival Cruise Line', href: '/kreuzfahrt-reederei/carnival-kreuzfahrten' },
                  { caption: 'Kreuzfahrt Hurtigruten Expeditions', href: '/kreuzfahrten/hurtigruten-expeditions' },
                  { caption: 'Kreuzfahrt Explora Journeys', href: '/luxuskreuzfahrten/explora-journeys' },
                ]" />

      <CListCard
                 claim="Reisen" title="Beliebte Reiseziele" description="Kurzurlaub, Sonne, Strand und mehr." img-url="images/country/spanien/balearen/ibiza/coast-3949782.jpg" :tags="['Reiseziele']"
                 :items="[
                  { caption: 'Urlaub Ibiza', href: '/urlaub/spanien/balearen/ibiza' },
                  { caption: 'Urlaub Mallorca', href: '/urlaub/spanien/balearen/mallorca' },
                  { caption: 'Urlaub Italien', href: '/urlaub/italien' },
                  { caption: 'Urlaub Portugal', href: '/urlaub/portugal' },
                  { caption: 'Urlaub Griechenland', href: '/urlaub/griechenland' },
                ]" />

      <CListCard
                 claim="Reisen" title="Rund um den Globus" description="Exotische Buchten und WOW Destinationen." img-url="images/asien/thailand/tom-lorber-unsplash.jpg" :tags="['Fernreiseziele']"
                 :items="[
                  { caption: 'Urlaub Dubai', href: '/urlaub/vereinigte-arabische-emirate/dubai' },
                  { caption: 'Urlaub Thailand', href: '/urlaub/asien/thailand' },
                  { caption: 'Urlaub Malediven', href: '/urlaub/asien/malediven' },
                  { caption: 'Urlaub Seychellen', href: '/urlaub/afrika/seychellen' },
                  { caption: 'Urlaub Mauritius', href: '/urlaub/afrika/mauritius' },
                ]" />

      <CListCard
                 claim="Rund- und Städtereisen" title="Beliebte Rund- und Städtereisen" description="Erlebe mit Ameropa die unterschiedlichsten Städte mit dem Zug und dem Schiff." img-url="images/nordamerika/usa/tetiana-shyshkina-eHMLHDd5Dco-unsplash.jpg" :tags="['Zugreisen', 'Mini-Kreuzfahrten']"
                 :items="[
                  { caption: 'Bahn-Wandern Jungfrauregion', href: 'https://www.ameropa.de/reiseangebote/client/720485/rundreisen/Schweiz-Wilderswil-Bahn-Wandern-Jungfrauregi/050987?travellers=35,35&fromDate=2024-10-30&toDate=2024-10-31&provider=AMEP&productRef=giata:100497335,goal:2042630&referrerPageName=srp&referrerSearchTerm=&roomCode=DZ1&boardCode=H' },
                  { caption: 'Kurztrip nach Südschweden', href: 'https://www.ameropa.de/reiseangebote/client/720485/rundreisen/Deutschland-L%C3%BCbeck-Kurztrip-nach-S%C3%BCdschweden/201675?travellers=35,35&fromDate=2024-10-18&toDate=2024-10-21&provider=AME&productRef=giata:100283492,goal:2013668&referrerPageName=srp&referrerSearchTerm=&roomCode=DZ1&boardCode=L' },
                  { caption: 'Glacier Express Individuell Zermatt - St.Moritz', href: 'https://www.ameropa.de/reiseangebote/client/720485/rundreisen/Schweiz-Zermatt-Glacier-Express-Ind/050011?travellers=35,35&fromDate=2024-10-03&toDate=2024-10-05&provider=AMEPAK-H&productRef=giata:100218769,goal:2059017&referrerPageName=srp&referrerSearchTerm=&roomCode=DZA&boardCode=F' },
                  { caption: 'Im African Explorer von Kapstadt nach Windhoek', href: 'https://www.ameropa.de/reiseangebote/client/720485/rundreisen/S%C3%BCdafrika-Kapstadt-African-Explorer/206316?travellers=35,35&fromDate=2024-10-30&toDate=2024-11-14&provider=AMEP&productRef=giata:100283496,goal:1868115&referrerPageName=srp&referrerSearchTerm=&roomCode=ZD2&boardCode=L' },
                ]" />

      <CListCard
                 claim="Kreuzfahrten" title="Beliebte Kreuzfahrtregionen" description="Erlebe die Karibik hautnah, erkunde Down Under oder gehe auf eine Expedition." img-url="images/ship/explora-journeys/explora-i/explora-i-1200x628-2.jpg"
                 :items="[
                  { caption: 'Karibik', href: $c.engine.urls.search + $c.engine.params.region.karibik },
                  { caption: 'Hawaii', href: $c.engine.urls.search + $c.engine.params.region.hawaii },
                  { caption: 'Bahamas', href: $c.engine.urls.search + $c.engine.params.region.bahamas },
                  { caption: 'Down Under', href: $c.engine.urls.search + $c.engine.params.region.downUnder },
                  { caption: 'Nordamerika', href: $c.engine.urls.search + $c.engine.params.region.nordamerika },
                ]" />

      <CListCard
                 claim="Information" title="Ratgeber und mehr" description="Wissenswertes rund um deine Reise." img-url="images/people/family/AdobeStock_372463783.jpeg"
                 :items="[
                  { caption: 'Häufige Fragen', href: '/service/haeufige-fragen' },
                  { caption: 'Einreise in die USA', href: '/ratgeber/reiserecht/einreise/usa-esta' },
                  { caption: 'Einreise nach Jamaika', href: '/ratgeber/reiserecht/einreise/jamaika' },
                ]" />
    </CPageColumnSection>

    <div data-aos="fade-up">
      <div>
        <NuxtLink href="/kreuzfahrt-reederei/msc-kreuzfahrten/" aria-label="MSC Kreuzfahrten" class="border-none outline-none hover:bg-white">
          <CHeroHeader class="cos-clip-i" lazy layout="large" alt="MSC, Discover the Future of Cruising" blend="bg-gradient-to-l from-amber-400 via-transparent to-fuchsia-600 opacity-50 saturate-200" src="images/cruises/misc/MSC23019435.jpg">
            <CHeroContentModern layout="center">
              <div class="px-0 sm:px-10 pb-2 text-transparent bg-clip-text bg-gradient-to-b from-sky-50 to-blue-300 font-black uppercase text-5xl md:text-6xl lg:text-7xl" style="filter: drop-shadow(1px 2px 3px #068);">Discover the Future of Cruising</div>
              <CButton data-aos="fade-right" design="Dark" target="_blank" content="Mehr erfahren" />
            </CHeroContentModern>
          </CHeroHeader>
        </NuxtLink>
      </div>
    </div>

    <div data-aos="fade-up" class="mt-3 sm:mt-16 text-center">
      <CPageContentSection class="text-center">
        <h3 data-aos="zoom-in" class="mb-3 font-light text-3xl sm:text-5xl tracking-tight">Exklusivität trifft Abenteuer: Jetzt zum <span class="font-black"> <strong>Traumurlaub</strong></span></h3>
        <p data-aos="fade-right" class="mb-3 font-light text-3xl sm:text-5xl tracking-tight">{{ company.nameFull }}</p>
        <CHeaderContent heading="h4" class="uppercase">Dein Traumurlaub ist nur einen Klick entfernt und wir freuen uns riesig darauf, ihn mit dir zu planen.</CHeaderContent>
        <p>Nichts begeistert uns mehr, als dir bei der <strong>Planung</strong> deines <strong>Traumurlaubs</strong> zu helfen! Unsere <strong>Reiseexperten</strong> sind nicht nur <strong>Fachleute</strong> - sie sind <strong>leidenschaftliche Weltenbummler</strong>, die ihr <strong>Wissen</strong> und ihre <strong>Erfahrung</strong> mit dir teilen, um dir die besten <strong>Insider-Tipps</strong> zu geben.</p>

        <div class="mt-16 text-start">
          <div class="mb-16 flex flex-col justify-center items-center">
            <NuxtPicture src="images/costeri/travel-hotline/costeri-agent.jpeg"
                         class="w-24 h-24 aspect-auto"
                         faceindex="1"
                         fit="facearea"
                         :quality="40"
                         :width="96" :height="96"
                         :alt="`${company.name} Reise-Hotline`"
                         :modifiers="{ auto: 'format,compress', facepad: '3', mask: 'ellipse' }" />
            <div class="font-sansScript font-bold text-3xl"><strong>Ruf uns an!</strong></div>
            <p class="pt-3 max-w-3xl text-center text-sm">Noch nicht das passende gefunden? Kein Problem!!! Ruf uns jetzt an und wir helfen dir deinen Traumurlaub zu planen. Wir sind gespannt auf deine Wünsche und ...<br></p>
            <p class="pt-2.5 pb-3 font-medium text-xl text-center">... wir freuen uns auf deinen Anruf!</p>
            <div class="font-normal text-lux-400 text-xl sm:text-3xl">{{ company.phoneNumberCruisesInternational }}</div>
          </div>

          <div class="text-xl">Alles aus einer Hand - <strong>individuelle Reiseplanung</strong>*</div>
          <div>
            <CFlowCols class="my-6" no-spacing>
              <ul class="cos-list-check space-y-2">
                <li>Reisen verkaufen können viele - richtig gute Beratung** findest du bei uns</li>
                <li>Unkompliziert und sicher</li>
                <li>Keine zeitaufwendigen Abstimmungen mit Veranstaltern</li>
                <li>Wir prüfen Voraussetzungen für deine Reise. Damit es z.B. keine böse Überraschung mit einem nicht gültigen Reisepass gibt.</li>
                <li>Top <strong>
                    <NuxtLink href="/service/reiseversicherungen/reiseschutz-von-ergo">Reiseversicherungen von ERGO</NuxtLink>
                  </strong>, Allianz und HanseMerkur</li>
                <li><strong>Rundreisen</strong>, <strong>Kreuzfahrten</strong>, <strong>Expeditionsreisen</strong>, Fitnessreisen, Zugreisen</li>
                <li>Flüge, Mietwagen, <strong>Hotels</strong>, Transfers, Privatjets, Concierge-Service</li>
                <li><strong>Dein Schutzengel</strong>: Aktuelle Ereignisse und Gefahren im Überblick mit einem eigenen A3M-Zugang von uns</li>
                <li>Top Preise garantiert</li>
                <li>Bequem <strong>buchen</strong> von Zuhause aus</li>
              </ul>
            </CFlowCols>
          </div>
          <p class="mt-2 text-xs italic">*Wenn du uns über Telefon, E-Mail, Internet oder WhatsApp kontaktierst, bist du damit einverstanden, dass die {{ company.nameOfficial }} für die Bearbeitung deines Anliegens personenbezogene Daten (wie z.B. deine Telefonnummer, deine Vorgangsnummer usw.) verarbeitet. Unseren vollständigen Datenschutzhinweis findest du <NuxtLink href="/datenschutz" aria-label="Datenschutz">hier</NuxtLink>. Wir empfehlen dir, immer die aktuellste Version des WhatsApp Messenger zu nutzen.</p>
          <p class="mt-2 text-xs italic">**Inklusive kostenlose Beratung bei allen Reisen</p>
        </div>
      </CPageContentSection>

      <div class="mt-16 flex flex-wrap justify-center max-w-7xl mx-auto">
        <CWidgetInfoItem icon="material-symbols:security-rounded">
          <template #caption>
            <h3>Sicherheit</h3>
          </template>
          <template #description>
            <p>Wir bieten dir eine riesige Auswahl an Urlaubsreisen, von Cluburlaub und Golfreisen über Kreuzfahrten bis zu Individualreisen.</p>
          </template>
        </CWidgetInfoItem>

        <CWidgetInfoItem icon="material-symbols:shield-person-outline-rounded">
          <template #caption>
            <h3>Persönliche Betreuung</h3>
          </template>
          <template #description>
            <p>Wir beraten beraten dich zu Urlaubsreisen gerne und unverbindlich. Haben wir dein Interesse geweckt? Dann ruf uns gleich an <br><strong>&#x2706; {{ company.phoneNumber }}</strong></p>
          </template>
        </CWidgetInfoItem>

        <CWidgetInfoItem icon="material-symbols:shield-spark-outline-rounded">
          <template #caption>
            <h3>Deine Vorteile</h3>
          </template>
          <template #description>
            <p>Wir sind spezialisiert auf die Buchung von Urlaubsreisen. Wir garantieren den besten Preis bei identischen Angeboten.</p>
            <CLinkWSymbol href="/pauschalreisen/suche" target="_blank" class="font-bold text-mongoose-600" label="Pauschalreisen online buchen" />
          </template>
        </CWidgetInfoItem>
      </div>
    </div>

    <CWidgetReiseanfrage />

  </div>
</template>

<script setup lang="ts">
const { company } = useAppConfig()
const $c = useCruiseCompass()
const $d = useDestination()

definePageMeta({ layout: 'default', name: 'Home' })

useSeoMeta({
  title: 'Hotels buchen: Die besten Hotels und Resorts 2024',
  description: 'Exklusive Hotels und Resorts an den angesagtesten Orten der Welt. Bereit für unvergessliche Erlebnisse? | Buche jetzt deine nächste Reise bei Costéri.',
  keywords: 'Luxusreisen, exklusive Reisen, Hotels, neue Hotels, individuelle Reisen, Fernreisen, USA Rundreisen, USA Rundreisen 2024, Rundreisen, Rundreisen 2024, Hotel Neueröffnungen, Malediven, Seychellen, Mexiko, Thailand, Dubai, Singapur, Hong Kong, Bangkok, Hotelneueröffnungen, Design Hotels, Resorts, neue Resorts, Hotel openings, lifestyle Hotels, neue Hotels 2023, Hoteleröffnungen, neue Hotels 2024, 2024, Neue Hotels 2023, 2023, Hotelneueröffnung 2024, Pauschalreise, Pauschalreisen, Hotels neu, Urlaub, Urlaub neu, Hotels Neuerscheinungen, urlaub neuerscheinungen, reisen neu, reisen neuerscheinungen, Ferien neu, Urlaub buchen, TUI Neueröffnungen, Kreuzfahrten, Kreuzfahrten 2024, Kreuzfahrten 2025, Luxuskreuzfahrten, Luxuskreuzfahrten 2024, Luxuskreuzfahrten 2025, Kreuzfahrt, Kreuzfahrt 2024, Kreuzfahrt 2025',
})
</script>

<!-- <h1 class="pt-10 uppercase font-bold text-4xl sm:text-6xl max-w-2xl tracking-tighter text-transparent bg-clip-text bg-gradient-to-tl from-lux-500 to-amber-50"> -->
<!-- <p class="font-bold uppercase text-transparent bg-clip-text bg-gradient-to-l from-lux-500 to-orange-100"> -->

<!-- <CPageContentSection class="mt-10 sm:mt-20 text-center">
      <CHeaderLight class="inline">Urlaubsreisen & europäischer <strong>Luxus</strong> auf dem Meer neu definiert mit </CHeaderLight>
      <CHeaderBold heading="h2" class="inline">Explora Journeys <strong>Urlaubsreisen</strong> <strong>Frühbuchervorteil</strong></CHeaderBold>
      <div class="mt-4">Erlebe kultivierten und excellenten Luxus der Extraklasse auf Urlaubsreisen mit höchstem Genußerlebnis.</div>
      <div>Reise weiter, tauche tiefer ein und verweile länger. Explora Journeys Luxuskreuzfahrten. Entdecke die Welt und Urlaubsreisen auf ganz neue Weise.</div>
      <CButton :href="`${cruiseIBE.url}red=9`" content="Mehr erfahren" />
    </CPageContentSection>-->

<!-- <CColsBannerItem href='/kreuzfahrt-reederei/aida-kreuzfahrten' imgUrl='images/ship/aida/aidaprima/AC_036786_AIDAprima.TIF' origin='Beliebt' alt='AIDA Cruises Kreuzfahrten'><strong>AIDA Cruises</strong> - Verlockung der Woche</CColsBannerItem> -->
<!-- <p>Wir bei <strong>{{ company.name }}</strong> glauben, dass ein <strong>Reisebüro</strong> der Ursprung jeder <strong>Urlaubsreise</strong> ist. Unsere <strong>Reiseexperten</strong> sind eine <strong>unschätzbare Informationsquelle</strong>, die dir hilft, <strong>Zeit und Geld zu sparen</strong> und den Stress der Urlaubsplanung zu vermeiden. Unsere <strong>Travel Designer</strong> sind Erfüller von Träumen, die dir alle nötigen Tipps zu <strong>Hotels</strong>, <strong>Kreuzfahrtschiffen</strong>, Aktivitäten und <strong>Reisezielen</strong> geben.</p> -->

<!-- <div class="flex items-center mb-3">
      <div class="w-8 h-8 mr-3 inline-flex items-center justify-center rounded-full dark:bg-indigo-500 bg-indigo-500 text-white flex-shrink-0">
        <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
          stroke-width="2" class="w-5 h-5" viewBox="0 0 24 24">
          <path d="M22 12h-4l-3 9L9 3l-3 9H2"></path>
        </svg>
      </div>
      <h2 class=" text-lg">Feature 1</h2>
    </div> -->

<!-- <div class="my-10">
      <NuxtLink :href="`${cruiseIBE.url}red=9`" aria-label="Angebot 35% Rabatt">
        <div class="p-8 border rounded container max-w-fit font-bold text-3xl text-transparent bg-gradient-to-tr bg-clip-text from-red-700 to-indigo-500">MSC - WOW Angebote mit bis zu 35% Rabatt</div>
      </NuxtLink>
    </div> -->

<!-- <div data-aos="fade-up">
    <CHeroHeader class="cos-clip-tb-lg drop-shadow-2xl" lazy layout="normal" blend="bg-gradient-to-l from-blue-600 to-lime-600 opacity-50 saturate-150"
      src="images/ship/explora-journeys/explora-i/explora-i-1200x628-2.jpg" :img-contrast="1.1">
      <NuxtLink href="/luxuskreuzfahrten/explora-journeys" aria-label="Ocean Journeys" class="border-none outline-none hover:bg-white">
        <CHeroContentModern>
          <div class="px-0 sm:px-10 pb-10 text-transparent bg-clip-text bg-gradient-to-b from-sky-50 to-blue-300 font-black uppercase text-5xl md:text-6xl lg:text-7xl" style="filter: drop-shadow(1px 2px 3px #068);">Ocean Journeys</div>
        </CHeroContentModern>
      </NuxtLink>
    </CHeroHeader>
  </div> -->

<!-- const buttonStyle = "px-4 py-2 mr-1 mb-1 flex font-bold text-xs uppercase text-slate-700 bg-gradient-to-br text-white from-indigo-500 to-blue-900 rounded border-none tracking-wider" -->

<!-- <LayoutCHeroTextImageSection class="rounded-b-2xl overflow-hidden"
    header="Exklusive Pauschal- und Luxusreisen" alt="Meerblick Seychellen"
    caption="Wir finden deine Traum&shy;hotels weltweit!" caption-heading="h1"
    src="images/afrika/seychellen/pexels-moritz-feldmann-5048621.jpg" /> -->

<!-- <CInfoBox data-aos="fade-up" class="text-center max-w-2xl mx-auto">
    <CHeaderGlamTitle>Es ist kein Geheimnis, dass unsere</CHeaderGlamTitle>
    <CHeaderGlamSubline>Urlaubsangebote einfach begeistern</CHeaderGlamSubline>
    <CHeaderContent>Denn davon wirst du begeistert sein - Fremde Länder &amp; Kulturen buchen bei Costéri. Mit maßgeschneiderten Angeboten geben wir dir die volle Kontrolle über deine Urlaubsplanung. Wir sind bei dir, wo auch immer du hin möchtest.</CHeaderContent>
  </CInfoBox> -->

<!-- <div data-aos="fade-up">
      <CHeroHeader lazy alt="Pauschalreisen, Urlaub, Strand" class="mb-10 cos-clip-tb-lg mx-auto max-w-7xl" :fp-y="0" src="images/people/group/AdobeStock_77821243.jpeg" />
      <CPageContentSection class="mt-10 flex flex-col justify-center items-center text-center">
        <CHeaderBold heading="h2">Buche, verreise und erlebe tolle Urlaubsziele</CHeaderBold>
        <CHeaderLight>Dieses wunderschöne Gefühl, gemeinsam mit deinen Lieben dem Rauschen des Meeres zu lauschen</CHeaderLight>
      </CPageContentSection>
    </div> -->

<!-- <CCruisesOfferBig modern pattern="Miami" color="bg-[#7F6541] bg-opacity-80" :pretty-url="`${cruiseIBE.urlOffer}cruise_id=822-241122-14&p=3&type=NS`" red_name="Explora Journeys" ship_name="Explora II" /> -->

<!-- <CPageContentSection class="text-center">
    <CHeaderLight class="inline">Urlaubsreisen & europäischer <strong>Luxus</strong> auf dem Meer neu definiert mit </CHeaderLight>
    <CHeaderBold heading="h2" class="inline">Explora Journeys <strong>Urlaubsreisen</strong> <strong>Frühbuchervorteil</strong></CHeaderBold>
    <div class="mt-4">Erlebe kultivierten und excellenten Luxus der Extraklasse auf Urlaubsreisen mit höchstem Genußerlebnis.</div>
    <div>Reise weiter, tauche tiefer ein und verweile länger. Explora Journeys Luxuskreuzfahrten. Entdecke die Welt und Urlaubsreisen auf ganz neue Weise.</div>
    <CButton :href="`${ibe.url}red=9`" content="Mehr erfahren" />
  </CPageContentSection> -->
<!-- <CHeaderLight heading="h2" class="mt-10 uppercase">Wow Angebote mit bis zu 35% Rabatt - 100% Luxus</CHeaderLight> -->

<!-- <div data-aos="fade-up">
      <CPageContentSection class="mb-16 text-center bg-gradient-to-t from-neutral-100 sm:bg-none">
        <CHeaderLight>Großartige Urlaubsziele kennenlernen?</CHeaderLight>
        <CHeaderBold heading="h3">Entdecke deine Traumziele und Regionen</CHeaderBold>
        <CHeaderSubline>Spare Zeit und Geld: Hoher Buchungskomfort und beste Preise - und das alles kostenlos</CHeaderSubline>
        <CButton href="/urlaub" />
      </CPageContentSection>
    </div> -->
